/* eslint-disable react/jsx-props-no-spreading */
import { useLocalStorageState, useMount } from 'ahooks';
import axios from 'axios';
import * as dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Provider as HttpProvider } from 'use-http';
import i18n from './i18n';
import CushionLayout from './modules/gmb/reviews/collection/cushionlayout';

import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import {
  AUTH_KEY,
  DEFAULT_LANG,
  DEFAULT_MENU_MODE,
  GROUP_KEY,
  HAS_FOOD_MENUS_KEY,
  HAS_SERVICE_ITEMS_KEY,
  IS_IG_CONSULT_KEY,
  LANG_KEY,
  LOCATION_KEY,
  MENU_MODE_KEY,
  SELECTED_LOCATION_ID_KEY,
  TOAST_SKIPPING_KEYS,
  USER_KEY,
} from './commons/constants/key';
import { BASE_URL } from './commons/constants/url';
import { AppContext } from './commons/helpers/appContext';
import { errorMessageGenerator } from './commons/helpers/validation';
import config from './OEMConfig';

// days js
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const customParseFormat = require('dayjs/plugin/customParseFormat');
// dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
// set default timezone
dayjs.tz.setDefault(dayjs.tz.guess());

// auth pages
const Login = React.lazy(() => import('./modules/auth/Login'));
const LoginByRoot = React.lazy(() => import('./modules/auth/LoginByRoot'));
const Forgot = React.lazy(() => import('./modules/auth/Forgot'));
const Reset = React.lazy(() => import('./modules/auth/Reset'));
const Success = React.lazy(() => import('./modules/auth/Success'));
const EmailSuccess = React.lazy(() => import('./modules/auth/SuccessEmail'));

// public pages

const Privacy = React.lazy(() => import('./modules/public/privacy'));
const TOS = React.lazy(() => import('./modules/public/tos'));
const TOS_CONSULTANT = React.lazy(() =>
  import('./modules/public/tos-consultant'),
);
const MenuTranslated = React.lazy(() =>
  import('./modules/basicInfo/MenuTranslated'),
);

// Containers
const Layout = React.lazy(() => import('./layouts/default/DefaultLayout'));
function App() {
  document.documentElement.style.setProperty(
    '--side-menu-color',
    config().side_menu_color,
  );
  document.documentElement.style.setProperty(
    '--side-menu-selected-color',
    config().side_menu_selected_color,
  );
  document.documentElement.style.setProperty(
    '--contrast-color',
    config().contrast_color,
  );
  // mob
  document.documentElement.style.setProperty(
    '--prime-color', // #cc0099
    config().side_menu_color,
  );
  document.documentElement.style.setProperty(
    '--third-color', // #fbebf7
    config().contrast_color,
  );
  document.documentElement.style.setProperty(
    '--background-color', // #f3f4f6
    '#f3f4f6',
  );
  document.documentElement.style.setProperty(
    '--tooltip-color', // #757575
    '#757575',
  );
  document.documentElement.style.setProperty('--border-color', '#bbbbbb');
  const history = useHistory();
  const [menuMode, setMenuMode] = useLocalStorageState(
    MENU_MODE_KEY,
    () => DEFAULT_MENU_MODE,
  );
  const [hasServiceItems, setHasServiceItems] = useLocalStorageState(
    HAS_SERVICE_ITEMS_KEY,
    () => false,
  );
  const [hasFoodMenus, setHasFoodMenus] = useLocalStorageState(
    HAS_FOOD_MENUS_KEY,
    () => false,
  );

  const [selectedLocationID, setSelectedLocationID] = useLocalStorageState(
    SELECTED_LOCATION_ID_KEY,
    () => null,
  );
  const [lang, setLang] = useLocalStorageState(LANG_KEY, () => DEFAULT_LANG);
  const [token, setToken] = useLocalStorageState(AUTH_KEY, () => null);
  const [user, setUser] = useLocalStorageState(USER_KEY, () => null);
  const [groups, setGroups] = useLocalStorageState(GROUP_KEY, () => []);
  const [closeSidebar, setCloseSidebar] = useState(false);
  const [recognitionCount, setRecognitionCount] = useState(0);
  const [locations, setLocations] = useLocalStorageState(
    LOCATION_KEY,
    () => [],
  );
  const [isIgConsult, setIsIgConsult] = useLocalStorageState(
    IS_IG_CONSULT_KEY,
    () => null,
  );
  const isAdmin = () => user?.roles?.some((role) => role.name === 'admin');
  const services = new Set(
    user?.locations
      ?.map((location) => location?.service?.id)
      ?.filter((item, i, ar) => ar.indexOf(item) === i),
  );
  // ユーザーがアクセス可能なロケーションのうち、1つでも各CMS連携がtrueのものがあるかどうか
  const showCms = () => {
    const showOnerank = user?.locations?.some(
      (location) => location?.is_onerank === 1,
    );
    const showOwlet = user?.locations?.some(
      (location) => location?.is_owlet === 1,
    );
    const showSplan = user?.locations?.some(
      (location) => location?.is_splan === 1,
    );
    const showWin = user?.locations?.some((location) => location?.is_win === 1);
    return {
      showOnerank,
      showOwlet,
      showSplan,
      showWin,
      showAnyCms: showOnerank || showOwlet || showSplan || showWin,
    };
  };
  // ユーザーがアクセス可能なロケーションのうち、1つでも各ヒトサラ連携がtrueのものがあるかどうか
  const isShowHitosara = () => {
    const showHitosara = user?.locations?.some(
      (location) => location?.is_hitosara === 1,
    );
    return {
      showHitosara,
    };
  };

  const isShowUberall = () => {
    const showUberall = user?.locations?.some(
      (location) => location?.is_uberall === 1,
    );
    return {
      showUberall,
    };
  };

  const getWhiteListedIds = () => {
    const parts = window.location.hostname.split('.');
    const subdomain = parts.shift();

    const localWhiteListIds = ['local', []];
    const devWhiteListIds = ['dev', [146]];
    const stgWhiteListIds = ['stg', [11]];
    const prodWhiteListIds = ['prod', [75, 92]];

    if (subdomain === 'localhost') {
      return localWhiteListIds;
    }
    if (subdomain === 'dev') {
      return devWhiteListIds;
    }
    if (subdomain === 'stg') {
      return stgWhiteListIds;
    }
    return prodWhiteListIds;
  };

  const isShowInfluencerDb = () => {
    let showInfluencerDb = true;
    const whiteListedIds = getWhiteListedIds();
    if (whiteListedIds[0] !== 'local') {
      showInfluencerDb = whiteListedIds[1].includes(user?.id);
    }
    return {
      showInfluencerDb,
    };
  };

  const isShowYplace = () => {
    const showYplace = config().is_yplace;
    return {
      showYplace,
    };
  };

  const changeLang = (language) => {
    setLang(language);
    i18n.changeLanguage(language);
  };
  useMount(() => {
    i18n.changeLanguage(lang);
  });

  useEffect(() => {
    setMenuMode(config().menu_mode || 'sidebar');
    const appName = process.env.REACT_APP_NAME;
    if (appName) {
      document.title = appName;
    } else {
      document.title = 'SNSを一括管理';
    }
    const link = document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = `${process.env.PUBLIC_URL}/favicon.ico`;
    }
  }, []);

  const responseInterceptor = ({ response }) => {
    if (response.status >= 400) {
      const message = errorMessageGenerator(response?.data);
      if (!TOAST_SKIPPING_KEYS.some((string) => string === message)) {
        toast.error(message);
      }
    }
    if (response.status === 401) {
      setToken();
      setUser();
      setIsIgConsult(false);
      history.push('/login');
    }
    return response;
  };
  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.common.lang = lang;
  axios.defaults.headers.common.authorization = `Bearer ${token}`;
  return (
    <AppContext.Provider
      value={{
        selectedLocationID,
        setSelectedLocationID,
        hasServiceItems,
        setHasServiceItems,
        hasFoodMenus,
        setHasFoodMenus,
        lang,
        token,
        user,
        menuMode,
        setMenuMode,
        changeLang,
        setToken,
        setUser,
        isAdmin: isAdmin(),
        services,
        groups,
        locations,
        setGroups,
        setLocations,
        setRecognitionCount,
        recognitionCount,
        closeSidebar,
        setCloseSidebar,
        showCms: showCms(),
        showHitosara: isShowHitosara(),
        showUberall: isShowUberall(),
        showInfluencerDb: isShowInfluencerDb(),
        showYplace: isShowYplace(),
        isIgConsult,
        setIsIgConsult,
      }}
    >
      <HttpProvider
        url={BASE_URL}
        options={{
          headers: { lang, authorization: `Bearer ${token}` },
          cachePolicy: 'no-cache',
          interceptors: {
            response: responseInterceptor,
          },
        }}
      >
        <div className="App">
          <BrowserRouter>
            <React.Suspense fallback={<div>Loading...</div>}>
              <Switch>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exact
                  path="/forgot-password"
                  name="Forgot Page"
                  render={(props) => <Forgot {...props} />}
                />
                <Route
                  path="/reset-password"
                  name="Reset Page"
                  render={(props) => <Reset {...props} />}
                />
                <Route
                  path="/success"
                  name="Success Page"
                  render={(props) => <Success {...props} />}
                />
                <Route
                  path="/email-success"
                  name="Email Success Page"
                  render={(props) => <EmailSuccess {...props} />}
                />
                <Route
                  path="/privacy"
                  name="Privacy Page"
                  render={(props) => <Privacy {...props} />}
                />
                <Route
                  path="/tos"
                  name="TOS Page"
                  render={(props) => <TOS {...props} />}
                />
                <Route
                  path="/tos-consultant"
                  name="Consultant TOS Page"
                  render={(props) => <TOS_CONSULTANT {...props} />}
                />
                <Route
                  exact
                  path="/add-review/:id"
                  name="Cushion Page"
                  render={(props) => <CushionLayout {...props} />}
                />
                <Route
                  exact
                  path="/review/add-new/:id"
                  name="Cushion Page"
                  render={() => <CushionLayout is_encrypted="true" />}
                />
                <Route
                  exact
                  path="/menu/translated/:id"
                  name="Menu Translated Page"
                  render={() => <MenuTranslated />}
                />
                <Route
                  exact
                  path="/login-by-root"
                  name="Login By Root Page"
                  render={(props) => <LoginByRoot {...props} />}
                />
                <Route
                  path="/"
                  name="Home"
                  render={(props) => <Layout {...props} />}
                />
              </Switch>
              <ToastContainer
                position="bottom-center"
                autoClose={7000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </React.Suspense>
          </BrowserRouter>
        </div>
      </HttpProvider>
    </AppContext.Provider>
  );
}

export default App;
